import React, { useState } from "react";
import Modal from "react-modal";
import { X } from "lucide-react";
import { Link } from "react-router-dom";
import * as images from "../../constant/images";
import PortalSections from "../../programs/mainprograms/PortalSections";

const HomePage = ({
  investorPortalRef,
  franchisePortalRef,
  advertisePortalRef,
  seoPortalRef,
  educationPortalRef,
  ecommercePortalRef,
  newsPortalRef,
  blogsPortalRef,
  partnerPortalRef, // New prop
}) => {
  const [content, setContent] = useState("");
  const [summary, setSummary] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const summarize = async () => {
    const apiUrl = "https://api.openai.com/v1/chat/completions";
    const apiKey = "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";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiKey}`,
    };
    const data = {
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content:
            "You are a helpful assistant. and you have to summarize the text provided by the user.",
        },
        { role: "user", content },
      ],
    };
    const response = await fetch(apiUrl, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });

    const result = await response.json();
    const summaryText = result.choices[0].message.content;

    setSummary(summaryText);
    setModalIsOpen(true); // Open the modal after setting the summary
  };

  return (
    <>
      <section className="section section-search">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-md-9 col-12">
              <div className="text-center mt-5 pt-lg-2 pt-0">
                <h1 className="text-capitalize mt-1 ps-0 ps-lg-5 mt-lg-5 pt-1 pt-lg-5 h1_home_cscps">
                  FOOTBALL REPUBLICS
                </h1>
                <p className="mb-4 fw-bold p_home_cscps hero_new_h1">
                  "Become a Football Billionaire"
                  {/* <br className="d-none d-lg-block" /> */}
                </p>
              </div>
              <div className="search-box d-flex gap-3 justify-content-center" style={{ marginTop: "16%" }}>
                <div className="form-group search-info">
                  <input
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    id="message"
                    name="message"
                    type="text"
                    className="form-control"
                    placeholder="AI Responsive ..."
                  />
                </div>
                <div>
                  <button
                    className="eye_btn search-btn p-2 rounded-2 ps-lg-4 pe-lg-4 ps-2 pe-2"
                    onClick={summarize}
                  >
                    Enter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid pt-4 pb-4">
        <div className="row g-0">
          <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
            <div className="text-center">
              <img src={images.Logo} alt="ACEMAIN" width={240} />
            </div>
          </div>
        </div>
      </div>
      <PortalSections
        investorPortalRef={investorPortalRef}
        franchisePortalRef={franchisePortalRef}
        advertisePortalRef={advertisePortalRef}
        seoPortalRef={seoPortalRef}
        educationPortalRef={educationPortalRef}
        newsPortalRef={newsPortalRef}
        ecommercePortalRef={ecommercePortalRef}
        blogsPortalRef={blogsPortalRef}
        partnerPortalRef={partnerPortalRef}
      />
      {/* Chat Prompt Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Summary Modal"
        ariaHideApp={false}
        style={{
          content: {
            width: "50%",
            height: "50%",
            margin: "auto",
            border: "3px solid #fe0000",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            height: "auto",
          },
        }}
      >
        <div className="modal-content">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1 className="text-capitalize mt-4 h1_home_cscps">Summary</h1>
            </div>
            <div>
              <button
                onClick={() => setModalIsOpen(false)}
                className="eye_btn rounded"
              >
                <X />
              </button>
            </div>
          </div>
          <p className="mt-3 mb-4 text-muted p_home_cscps">{summary}</p>
          <Link to="/blogs-home-page">Go to the articles</Link>
        </div>
      </Modal>
    </>
  );
};

export default HomePage;
